<div class="lang-links">
  <div (click)="changeLang('ru')" [ngClass]="{'active' : currentLang === 'ru'}" class="lang-link">{{'ru' | translate}}</div>
  <div (click)="changeLang('en')" [ngClass]="{'active' : currentLang === 'en'}" class="lang-link middle">{{'en' | translate}}</div>
  <div (click)="changeLang('ua')" [ngClass]="{'active' : currentLang === 'ua'}" class="lang-link">{{'ua' | translate}}</div>
</div>


<div class="topline-lang-panel" [class.active]="active">

  <div class="current-lang" (click)="active = !active">
    {{currentLang | translate}}
  </div>
  <div *ngIf="currentLang !== 'ru'" class="mobile-lang-link topline-lang-dropdown" (click)="changeLang('ru')">
    {{'ru' | translate}}
  </div>
  <div *ngIf="currentLang !== 'en'" class="mobile-lang-link topline-lang-dropdown" (click)="changeLang('en')">
    {{'en' | translate}}
  </div>
  <div *ngIf="currentLang !== 'ua'" class="mobile-lang-link topline-lang-dropdown" (click)="changeLang('ua')">
    {{'ua' | translate}}
  </div>
</div>
