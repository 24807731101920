import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../services/language.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.css']
})
export class LanguagesComponent implements OnInit {
  public currentLang: string;
  active = false;

  constructor(public languageService: LanguageService,
              public translate: TranslateService) { }

  ngOnInit(): void {
    this.currentLang = this.languageService.getLang();
  }

  changeLang(lang) {
    if (this.languageService.getLang() !== lang) {
      sessionStorage.removeItem('firstLoad')
      location.reload();
    }
    this.languageService.setLang(lang);
    this.currentLang = this.languageService.getLang();
  }

}
