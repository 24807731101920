import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-accordion-menu',
  templateUrl: './accordion-menu.component.html',
  styleUrls: ['./accordion-menu.component.css']
})
export class AccordionMenuComponent {
  menuActive = false;
  showService = false;
  showCase = false;
  showBlog = false;

  constructor(public translate: TranslateService,
              public languageService: LanguageService) { }

  over(line) {
    line.classList.add('hover');
    line.classList.remove('leave');
    line.classList.remove('go');
  }

  leave(line) {
    line.classList.remove('hover');
    line.classList.add('leave');
  }

  getServiceStrokeImage() {
    let strokeImgUrl = '';
    switch (this.translate.currentLang) {
      case 'ru':
        strokeImgUrl = 'assets/img/menu-images/ru/stroke/services_rus_stroke.svg';
        break;
      case 'ua':
        strokeImgUrl = 'assets/img/menu-images/ua/stroke/services_ukr_stroke.svg';
        break;
      case 'en':
        strokeImgUrl = 'assets/img/menu-images/en/stroke/services_en_stroke.svg';
        break;
    }

    return strokeImgUrl;
  }

  getServiceFillImage() {
    let fillImgUrl = '';
    switch (this.translate.currentLang) {
      case 'ru':
        fillImgUrl = 'assets/img/menu-images/ru/fill/services_rus_fill.svg';
        break;
      case 'ua':
        fillImgUrl = 'assets/img/menu-images/ua/fill/services_ukr_fill.svg';
        break;
      case 'en':
        fillImgUrl = 'assets/img/menu-images/en/fill/services_en_fill.svg';
        break;
    }

    return fillImgUrl;
  }

  getCasesStrokeImage() {
    let strokeImgUrl = '';
    switch (this.translate.currentLang) {
      case 'ru':
        strokeImgUrl = 'assets/img/menu-images/ru/stroke/cases_rus_stroke.svg';
        break;
      case 'ua':
        strokeImgUrl = 'assets/img/menu-images/ua/stroke/cases_ukr_stroke.svg';
        break;
      case 'en':
        strokeImgUrl = 'assets/img/menu-images/en/stroke/cases_en_stroke.svg';
        break;
    }

    return strokeImgUrl;
  }

  getCasesFillImage() {
    let fillImgUrl = '';
    switch (this.translate.currentLang) {
      case 'ru':
        fillImgUrl = 'assets/img/menu-images/ru/fill/cases_rus_fill.svg';
        break;
      case 'ua':
        fillImgUrl = 'assets/img/menu-images/ua/fill/cases_ukr_fill.svg';
        break;
      case 'en':
        fillImgUrl = 'assets/img/menu-images/en/fill/cases_en_fill.svg';
        break;
    }

    return fillImgUrl;
  }

  getBlogStrokeImage() {
    let strokeImgUrl = '';
    switch (this.translate.currentLang) {
      case 'ru':
        strokeImgUrl = 'assets/img/menu-images/ru/stroke/blog_rus_stroke.svg';
        break;
      case 'ua':
        strokeImgUrl = 'assets/img/menu-images/ua/stroke/blog_ukr_stroke.svg';
        break;
      case 'en':
        strokeImgUrl = 'assets/img/menu-images/en/stroke/blog_en_stroke.svg';
        break;
    }

    return strokeImgUrl;
  }

  getBlogFillImage() {
    let fillImgUrl = '';
    switch (this.translate.currentLang) {
      case 'ru':
        fillImgUrl = 'assets/img/menu-images/ru/fill/blog_rus_fill.svg';
        break;
      case 'ua':
        fillImgUrl = 'assets/img/menu-images/ua/fill/blog_ukr_fill.svg';
        break;
      case 'en':
        fillImgUrl = 'assets/img/menu-images/en/fill/blog_en_fill.svg';
        break;
    }

    return fillImgUrl;
  }
}
