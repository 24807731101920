import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(private translate: TranslateService) { }

  setLang(value) {
    localStorage.setItem('lang', value);
    this.translate.use(value);
  }

  getLang() {
    return localStorage.getItem('lang');
  }
}
