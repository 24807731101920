import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './components/menu/menu.component';
import { AccordionMenuComponent } from './components/accordion-menu/accordion-menu.component';
import { MusicComponent } from './components/music/music.component';
import { LanguagesComponent } from './components/languages/languages.component';
import { TopLogoComponent } from './components/top-logo/top-logo.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';

const components = [
  MenuComponent,
  AccordionMenuComponent,
  MusicComponent,
  LanguagesComponent,
  TopLogoComponent
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    HttpClientModule
  ],
  exports : components
})
export class SharedModule { }
