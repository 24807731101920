import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MusicService } from '../../services/music.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-music',
  templateUrl: './music.component.html',
  styleUrls: ['./music.component.css']
})
export class MusicComponent implements OnInit, OnDestroy {
  public isPlayed = false;
  public subscription: Subscription;
  @ViewChild('audio') audioElement: ElementRef;
  @ViewChild('audioBtn') audioBtn: ElementRef;

  constructor(public musicService: MusicService) { }

  ngOnInit(): void {
    this.subscription = this.musicService.canPlay().subscribe((res) => {
      if (!res) {
        this.audioElement.nativeElement.pause();
        this.isPlayed = false;
      } else if (res && sessionStorage.getItem('firstLoad') === 'true') {
        setTimeout(() => this.audioBtn.nativeElement.click(), 0);
      }
    });
  }

  play(audio) {
    if (this.isPlayed) {
      audio.pause();
    } else {
      audio.play();
    }
    this.isPlayed = !this.isPlayed;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
