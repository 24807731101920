<router-outlet></router-outlet>

<div class="preloader">
  <div class="preloader-anim-logo-wrapper">

    <div class="logo-img">
      <img src="../assets/img/@1x/logo.png" alt="img" srcset="../assets/img/@1x/logo.png 1x, ../assets/img/@2x/logo.png 2x">
    </div>
    <div style="" id="preloader-anim-logo" class="preloader-anim-logo"></div>
  </div>
</div>

<div class="page-video-bg">
  <video loop autoplay muted playsinline webkit-playinginline class="headervideo" id="headervideo" #videoElement>
    <source src="../assets/img/Black_fur_video_v1-1.mp4"/>
    <p>{{'YOUR_BROWSER_DOES_NOT_SUPPORT_VIDEO' | translate}}</p>
  </video>
</div>

<div class="page-fixed-items">
  <app-accordion-menu></app-accordion-menu>

  <app-languages></app-languages>

  <app-menu></app-menu>

  <app-music></app-music>

</div>

<div class="loader" [style.display]="showLoader ? 'flex' : 'none'">
  <div class="progress-block" #progressBlock>
    <div class="progress" #progress>{{percent}}%</div>
    <div class="line">
      <span class="main-line"></span>
      <span class="white-line" #progressLine></span>
    </div>

    <div class="languages" #langs>
      <div class="lang" (click)="startApp()">
        <div class="inner"></div>
        {{'ENTER' | translate}}
      </div>
    </div>
  </div>
</div>
