import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './shared/services/language.service';
import { MusicService } from './shared/services/music.service';
declare function runScript(): any;
declare function goLoad(): any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'bcat';
  percent = 0;
  showLoader = true;
  @ViewChild('videoElement') videoElement: ElementRef;
  @ViewChild('progress') progress: ElementRef;
  @ViewChild('progressLine') progressLine: ElementRef;
  @ViewChild('progressBlock') progressBlock: ElementRef;
  @ViewChild('langs') langs: ElementRef;

  constructor(public translate: TranslateService,
              public languageService: LanguageService,
              private musicService: MusicService) {
    translate.addLangs(['en', 'ru', 'ua']);
    this.translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    if (sessionStorage.getItem('firstLoad') === 'false') {
      this.goFirstAnimation();
    } else {
      this.goLoader();
    }
    if (this.languageService.getLang()) {
      this.languageService.setLang(this.languageService.getLang());
    } else {
      this.languageService.setLang('en');
    }

    setTimeout(() => {
      const media = this.videoElement.nativeElement;
      media.muted = true;
      media.play();
    } , 1500);
  }

  goLoader() {
    setTimeout(() => {
      const startInterval = setInterval(() => {
        if (this.percent !== 100) {
          this.percent++;
          this.progress.nativeElement.textContent = this.percent + '%';
          this.progressLine.nativeElement.style.width = this.percent + '%';
        } else if (this.percent === 100) {
          clearInterval(startInterval);
          this.progressLine.nativeElement.classList.add('finish');
          this.progress.nativeElement.classList.add('finish');
          this.progressLine.nativeElement.style.width = 0;
          setTimeout(() =>  this.langs.nativeElement.style.opacity = 1, 2000);
        }
      }, 5);
    }, 0);
  }

  goFirstAnimation() {
    this.showLoader = false;
    setTimeout(() => {
      goLoad();
      runScript();
     }, 200);
    this.musicService.playMusic$.next(true);
    setTimeout(() => sessionStorage.setItem('firstLoad', 'false'), 500);
  }

  startApp() {
    this.langs.nativeElement.classList.add('started');
    this.progressBlock.nativeElement.classList.add('started');
    sessionStorage.setItem('firstLoad', 'true');

    setTimeout(() => {
      this.showLoader = false;
      this.goFirstAnimation();
      }, 1700);
  }
}
