<div class="menu-btn" [ngClass]="{'not-active' : menuActive}" (click)="menuActive = !menuActive">
  <span class="menu-btn-line"></span>
  <span class="menu-btn-line"></span>
  <span class="menu-btn-line"></span>
</div>

<div class="to-home-wr" [ngClass]="{'active' : menuActive}">
  <div class="back-to-home" (mouseover)="over(line)"
       (mouseleave)="leave(line)" routerLink="/home" (click)="menuActive = false">
    <span>{{'BACK' | translate}}</span>
    <span>{{'TO_HOMEPAGE' | translate}}</span>

    <div class="line" [class.go]="menuActive" #line></div>
  </div>
</div>


<div [ngClass]="{'active' : menuActive}" class="active-menu">
  <div class="menu-btn" (click)="menuActive = !menuActive" routerLink="/home">
    <img src="../../../assets/img/svg/close.svg" alt="">
  </div>

  <div class="menu-block" *ngIf="menuActive">
    <div class="menu">
      <div class="menu-item" (mouseover)="showService = true" (mouseleave)="showService = false" routerLink="/home" fragment="servicesBlock" (click)="menuActive = false">
        <img [ngClass]="!showService ? 'item-active' : 'item-not-active'" class="item desktop-item"
             [src]="getServiceStrokeImage()" alt="">
        <div class="filled-item-wr" [ngClass]="{'active-wr': showService}">
          <img [ngClass]="showService ? 'item-active' : 'item-not-active'" class="item filled-item mobile-item"
               [src]="getServiceFillImage()" alt="">
          <div class="item-line"></div>
        </div>
      </div>

      <div class="menu-item" (mouseover)="showCase = true" (mouseleave)="showCase = false" routerLink="/portfolio" (click)="menuActive = false">
        <img [ngClass]="!showCase ? 'item-active' : 'item-not-active'" class="item desktop-item"
             [src]="getCasesStrokeImage()" alt="">
        <div class="filled-item-wr" [ngClass]="{'active-wr': showCase}">
          <img [ngClass]="showCase ? 'item-active' : 'item-not-active'" class="item filled-item mobile-item"
               [src]="getCasesFillImage()" alt="">
          <div class="item-line"></div>
        </div>
      </div>

      <a [href]="'https://dev.bcat.tech/blog/?lang=' + languageService.getLang()" target="_blank"
        class="menu-item" (mouseover)="showBlog = true" (mouseleave)="showBlog = false">
          <img [ngClass]="!showBlog ? 'item-active' : 'item-not-active'" class="item desktop-item"
               [src]="getBlogStrokeImage()" alt="">
          <div class="filled-item-wr" [ngClass]="{'active-wr': showBlog}">
            <img [ngClass]="showBlog ? 'item-active' : 'item-not-active'" class="item filled-item mobile-item"
                 [src]="getBlogFillImage()" alt="">
            <div class="item-line"></div>
          </div>
      </a>

      <div class="contact-link" routerLink="/contacts" (click)="menuActive = false">
        <div class="top-contact-line"></div>
        <span class="button-text">
          {{'CONTACT_US' | translate}}
        </span>
        <div class="bottom-contact-line"></div>
      </div>
    </div>

    <div class="info">
      <div class="contacts">
        <div class="contact">
          <div class="contact-place">
            Kyiv, Ukraine
          </div>
        </div>
        <div class="contact">
          <a href="mailto:info@bcat.tech" class="contact-mail">
            info@bcat.tech
          </a>
        </div>
        <div class="contact">
          <a href="tel:+380443344543" class="contact-phone">
            + 38 (044) 33 44 543
          </a>
        </div>
      </div>

      <div class="contact-soc-links">
        <a href="https://www.facebook.com/Black-Cat-Technology-112700963858840" target="_blank" class="contact-soc-link contact-soc-link--facebook"></a>
        <a href="https://www.instagram.com/black.cat.technology/" target="_blank" class="contact-soc-link contact-soc-link--instagram"></a>
        <a href="https://www.behance.net/bcattech" target="_blank" class="contact-soc-link contact-soc-link--behance"></a>
        <a href="https://dribbble.com/BCatTech/collections" target="_blank" class="contact-soc-link contact-soc-link--dribbble"></a>
      </div>
    </div>
  </div>

</div>
