import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MusicService {

  public playMusic$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor() { }

  stopPlaying() {
    this.playMusic$.next(false);
  }

  canPlay(): Observable<boolean> {
    return this.playMusic$.asObservable();
  }

}
